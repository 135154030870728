import { useState, useRef, useEffect } from "react";

function Slide({arr, except, name, data}) {
    const scrollRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const [currentImg, setCurrentImg] = useState(0);
    const [prevImg, setPrevImg] = useState(arr.length-1);
    const [nextImg, setNextImg] = useState(1);
    const [button, setButton] = useState(false);
    const [animation, setAnimation] = useState(false);
    const [direction, setDirection] = useState();
    const imgRef = useRef(null);

    const [cursor, setCursor] = useState(null);

    const onMouseDown = (e) => {
        if (!button && arr.length > 1) {
            setCursor(e.changedTouches[0].pageX);
        }
    }

    useEffect(() => {
        if (cursor !== null) {
            imgRef.current.addEventListener("touchmove", onMouseMove);
            window.addEventListener("touchend", onMouseUp);
        }
    }, [cursor])

    const onMouseMove = (e) => {
        imgRef.current.style.setProperty('transform', `translateX(${e.pageX-cursor}px)`);
    }

    const onMouseUp = (e) => {
        imgRef.current.removeEventListener("touchmove", onMouseMove);
        window.removeEventListener("touchend", onMouseUp);

        if (Math.abs(e.pageX-cursor) > 30) {
            if (e.pageX-cursor<0) {
                onClick("right");
            } else if (e.pageX-cursor>0) {
                onClick("left");
            }
        } else {
            imgRef.current.style.setProperty('transform', `translateX(0px)`);
        }
    }

    const onClick = (arg) => {
        if (!button) {
            setButton(true);
            setAnimation(true);

            if (arg === "right") {
                setDirection(nextImg);
                imgRef.current.style.setProperty('transform', `translateX(-33.33%)`);
            } else if (arg === "left") {
                setDirection(prevImg);
                imgRef.current.style.setProperty('transform', `translateX(33.33%)`);
            }
        }
    }

    const reset = () => {
        imgRef.current.style.setProperty('transform', `translateX(0px)`);

        if (currentImg > 0) {
            setPrevImg(currentImg-1);
        } else if(currentImg <= 0) {
            setPrevImg(arr.length-1);
        }

        if (currentImg < arr.length-1) {
            setNextImg(currentImg+1);
        } else {
            setNextImg(0);
        }
        setButton(false);
    }




    function yScrollEvent() {
        if (window.innerHeight >= scrollRef.current.getBoundingClientRect().top) {
            setLoading(true);
        }
    }

    useEffect(() => {
        yScrollEvent();
        window.addEventListener("scroll", yScrollEvent);
        window.addEventListener("resize", yScrollEvent);
        return() => {
            window.removeEventListener("scroll", yScrollEvent);
            window.removeEventListener("resize", yScrollEvent);
        }
    }, [])

    const imageStyle = "pointer-events-none h-full drop-shadow-[0_10px_10px_rgba(0,0,0,0.15)]"
    const imageBoxStyle = "flex justify-center h-[65vw] w-[100%] bg-gray-300/5 p-[3vh]"

    return(
        <div class="flex flex-col items-center w-full border-t border-black" ref={scrollRef}>
            <div class="flex flex-row items-center justify-center w-full gap-4 pt-8">
                {arr.length > 1 ? <img src="images/왼쪽.png" class="h-[30px] select-none" onClick={() => {onClick("left")}}></img>:<div class="size-[30px] select-none"/>}
                <div class="overflow-hidden w-full border border-black/20">
                    <div class={`flex justify-center items-center w-[300%] relative -left-[100%] select-none ${animation ? "duration-300 ease-out":null}`} 
                    onTransitionEnd={() => {setCurrentImg(direction); setAnimation(false);}} ref={imgRef} onTouchStart={onMouseDown}>
                        {loading ?
                        <>
                        <div class={`${imageBoxStyle}`}><img src={arr[prevImg]} alt="" class={`${imageStyle}`}></img></div>
                        <div class={`${imageBoxStyle}`}><img src={arr[currentImg]} alt="" class={`${imageStyle}`} onLoad={reset}></img></div>
                        <div class={`${imageBoxStyle}`}><img src={arr[nextImg]} alt="" class={`${imageStyle}`}></img></div>
                        </>
                        :<div class="flex justify-center items-center h-[650px] w-full animate-spin"><img src="images/loading.png" alt="" width="70px"/></div>}
                    </div>
                </div>
                {arr.length > 1 ? <img src="images/왼쪽.png" class="h-[30px] -scale-x-100 select-none" onClick={() => {onClick("right")}}></img>:<div class="size-[30px] select-none"/>}
            </div>
            <div class="font-pop text-[17px] border-b border-gray-400 mt-2 px-6 py-2">{name[currentImg]}</div>
            <div class={`grid grid-cols-[repeat(auto-fit,80px)] pc:grid-cols-[repeat(auto-fit,100px)] w-full justify-center justify-items-center mt-2 gap-2 pc:gap-3 py-3`}>
                {arr.map((data, index) => (
                    <div key={index} class={`flex justify-center items-center justify-self-center size-[80px] pc:size-[100px] border shadow-sm border-gray-300 ${currentImg===index ? "shadow-md shadow-black/80":null}`} onClick={() => {setCurrentImg(index)}}>
                        {loading ? <img src={data} alt="" class="h-[85%]"></img>:null}
                    </div>
                ))}
            </div>
            <div class="flex flex-row justify-center text-[15px] border-t border-gray-400 font-Freesentation gap-4 pt-2 pb-3 mt-2 px-8">
                {data.map((data) => (
                    <span>{data}</span>                   
                ))}
            </div>
        </div>
    )
}

export default Slide;