import { React, useEffect, useRef, useState } from "react"

function Count({ChangeList, obj, item, soldout}) {
    const [itemCount, setItemCount] = useState();
    const countInput = useRef();

    const [loading, setLoading] = useState(false);
    const scrollRef = useRef(null);

    function increaseNumber() {
        setItemCount(Number(itemCount) + 1);
    };

    function onSelect(e) { 
        e.target.select();
    };

    function decreaseNumber() {
        if (itemCount !== 0) {
            setItemCount(itemCount - 1);            
        }
    };

    function inputOnchange(e) {
        let count = Number(e.target.value);

        if (isNaN(count)) {
        } else {
            setItemCount(count);
        }
    };

    function onBlur() {
        if (itemCount === "") {
            setItemCount(0);
        }
    }

    const handleEnter = (e) => {
        if (e.key === "Enter") {
          countInput.current.blur();
        }
      };

    /* eslint-disable */
    useEffect(() => {
        setItemCount(obj.count);
    }, [])

    /* eslint-disable */
    useEffect(() => {
        ChangeList(item, itemCount, obj);
    }, [itemCount]);

    useEffect(() => {
        yScrollEvent();
        window.addEventListener("scroll", yScrollEvent);
        window.addEventListener("resize", yScrollEvent);
        return() => {
            window.removeEventListener("scroll", yScrollEvent);
            window.removeEventListener("resize", yScrollEvent);
        }
    }, [])
    
    const yScrollEvent = () => {
        if (window.innerHeight >= scrollRef.current.getBoundingClientRect().top) {
            setLoading(true);
        }
    }

    return (
        <>
        <div class="flex flex-row border border-gray-500 h-[95px] p-2 rounded-md bg-white" ref={scrollRef}>
            <div class="flex justify-center items-center h-full w-[75px]">{loading ? <img src={obj.image} alt="" class="h-[75px] border border-gray-400"/>:null}</div>
            <div class="flex flex-col flex-1">
                <div class="flex-1 w-full ml-[2vw] self-start pt-2 text-[3.4vw] pc:text-[1em] font-Freesentation whitespace-pre-wrap;">{obj.item}</div>
                <div class="flex relative h-full items-center justify-between mt-[3vw] pc:mt-0">
                    <div class="ml-[2vw] font-noto_sans text-[3.5vw] pc:text-[1em]">{soldout ? <div class="font-noto_sans text-red-500">품절되었습니다</div>:<div>{obj.amount.toLocaleString('ko-KR')}원</div>}</div>
                    <div class={`grid grid-cols-5 rounded-md absolute right-0 self-center w-[30vw] h-[7vw] pc:w-[150px] pc:h-[35px] pc:mr-2 ${soldout ? "invisible":null}`}>
                        <button class="flex justify-center items-center border border-gray-400" onClick={decreaseNumber}><span class="material-icons">remove</span></button>
                        <div class="flex justify-center col-span-3 items-center border-y text-sm border-gray-400">
                            <input value={itemCount} onChange={inputOnchange} ref={countInput} onKeyDown={handleEnter} onFocus={onSelect} onBlur={onBlur} 
                            class="text-center outline-none w-full"></input>
                        </div>
                        <button class="flex justify-center items-center border border-gray-400" onClick={increaseNumber}><span className="material-icons">add</span></button>  
                    </div>
                </div>                
            </div>
        </div>

        </>
    );
}

export default Count;