import { useEffect, useRef, useState } from "react";

function Slide({arr, name, data}) {
    const scrollRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const [currentImg, setCurrentImg] = useState(0);
    const [prevImg, setPrevImg] = useState(arr.length-1);
    const [nextImg, setNextImg] = useState(1);
    const [button, setButton] = useState(false);
    const [direction, setDirection] = useState();
    const imgRef = useRef(null);

    const [cursor, setCursor] = useState(null);

    useEffect(() => {
        yScrollEvent();
        window.addEventListener("scroll", yScrollEvent);
        window.addEventListener("resize", yScrollEvent);
        return() => {
            window.removeEventListener("scroll", yScrollEvent);
            window.removeEventListener("resize", yScrollEvent);
        }
    }, [])
    
    const yScrollEvent = () => {
        if (window.innerHeight >= scrollRef.current.getBoundingClientRect().top) {
            setLoading(true);
        }
    }



    

    const onMouseDown = (e) => {
        if (!button) {
            setCursor(e.pageX);
        }
    }

    useEffect(() => {
        if (cursor !== null) {
            imgRef.current.addEventListener("mousemove", onMouseMove);
            window.addEventListener("mouseup", onMouseUp);
        }
    }, [cursor])

    const onMouseMove = (e) => {
        imgRef.current.style.setProperty('transform', `translateX(${e.pageX-cursor}px)`);
    }

    const onMouseUp = (e) => {
        imgRef.current.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mouseup", onMouseUp);

        if (Math.abs(e.pageX-cursor) > 70) {
            if (e.pageX-cursor<0) {
                onClick("right");
            } else if (e.pageX-cursor>0) {
                onClick("left");
            }
        } else {
            imgRef.current.style.setProperty('transform', `translateX(0px)`);
        }
    }

    const onClick = (arg) => {
        if (!button) {
            setButton(true);

            if (arg === "right") {
                setDirection(nextImg);
                imgRef.current.style.setProperty('transform', `translateX(-33.33%)`);
            } else if (arg === "left") {
                setDirection(prevImg);
                imgRef.current.style.setProperty('transform', `translateX(33.33%)`);
            }
        }
    }

    const reset = () => {
        imgRef.current.style.setProperty('transform', `translateX(0px)`);

        if (currentImg > 0) {
            setPrevImg(currentImg-1);
        } else if(currentImg <= 0) {
            setPrevImg(arr.length-1);
        }

        if (currentImg < arr.length-1) {
            setNextImg(currentImg+1);
        } else {
            setNextImg(0);
        }
        setButton(false);
    }

    const imageStyle = "h-full pointer-events-none drop-shadow-[0_10px_10px_rgba(0,0,0,0.15)]"
    const imageBoxStyle = "flex justify-center w-[100%] bg-gray-300/5 p-8"

    return(
        <div class="flex flex-col items-center w-[100%]" ref={scrollRef}>
            <div class="flex flex-row items-center justify-around w-full pt-8">
                {arr.length>1 ?<img src="images/왼쪽.png" class="h-[70px]" onClick={() => {onClick("left")}}></img>:<div class="size-[70px]"></div>}
                <div class="w-[70%] overflow-hidden border-[3px] rounded-md border-black/20">
                    <div class={`flex flex-row relative w-[300%] h-[650px] -left-[100%] select-none ${button !== false ? "duration-300 ease-out":null}`} 
                    onTransitionEnd={() => {setCurrentImg(direction); setButton(false);}} ref={imgRef} onMouseDown={onMouseDown}> 
                        {loading ?
                        <>
                        <div class={`${imageBoxStyle}`}><img src={arr[prevImg]} alt="" class={`${imageStyle}`}></img></div>
                        <div class={`${imageBoxStyle}`}><img src={arr[currentImg]} alt="" class={`${imageStyle}`} onLoad={reset}></img></div>
                        <div class={`${imageBoxStyle}`}><img src={arr[nextImg]} alt="" class={`${imageStyle}`}></img></div>
                        </>
                        :<div class="flex justify-center items-center h-[650px] w-full animate-spin"><img src="images/loading.png" alt="" width="70px"/></div>}
                    </div>
                </div>
                {arr.length>1 ?<img src="images/왼쪽.png" class="h-[70px] -scale-x-100" onClick={() => {onClick("right")}}></img>:<div class="size-[70px]"></div>}
            </div>
            <div class="font-pop text-[30px] border-b border-gray-400 mt-8 px-6 py-2">{name[currentImg]}</div>
            <div class="flex flex-row mt-2 gap-2 p-6">
                {arr.map((data, index) => (
                    <div key={index} class={`flex justify-center items-center size-[100px] border shadow-sm border-gray-300 ${currentImg===index ? "shadow-md shadow-black":null}`} onClick={() => {setCurrentImg(index)}}>
                        {loading ? <img src={data} alt="" class="h-[80px]"></img>:null}
                    </div>
                ))}
            </div>
            <div class="flex flex-row justify-center text-[24px] border-t border-gray-400 w-full font-Freesentation gap-6 py-4 mt-2">
                {data.map((data) => (
                    <span>{data}</span>                   
                ))}
            </div>
        </div>
    );        
}

export default Slide;