import { useState, useEffect, useRef } from "react";
import Input from "./source/m_input";
import Inpo from "./source/m_inpo";
import { json, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
// import DaumPostcode from 'react-daum-postcode';

function CustomerData_m({itemList, dataList, valueList, changeDataList, route}) {
    const navigate = useNavigate();
    const [check, setCheck] = useState(0);

    const [itemPage, setItemPage] = useState([]);
    const [modal, setModal] = useState(false);
    const [error, setError] = useState(false);
    // const [zipCodeModal, setZipCodeModal] = useState(false);
    // const [checkBox, setCheckBox] = useState(false);

    // const {name, callNumber, email, accountNumber, d_name, d_callNumber, zipCode, address, detailAddress, memo} = valueList;
    const {name, callNumber, email, accountNumber, bank} = valueList;

    useEffect(() => {
        if (route === "user") {
            setTimeout(() => {window.scrollTo({top:0})}, 10);
        }
    }, [route])

    const onModal = () => {
        let obj = Object.values(valueList).slice(0, 9).every(x => x !== '')

        if (obj === true) {
            setModal(true);
        } else {
            setError(true);
            setCheck(check + 1);
        };
    }

    const initList = () => {
        return(        
            Object.values(itemList).filter((x) => x.count > 0)
        )
    }

    // const completeFunction = (e) => {
    //     changeDataList("address", e.address);
    //     changeDataList("zipCode", e.zonecode);
    //     setZipCodeModal(false);
    // }

    // const changeCheckBox = () => {
    //     if (checkBox === true) {
    //         changeDataList("d_name", "");
    //         changeDataList("d_callNumber", "");
    //         setCheckBox(false);
    //     } else {
    //         changeDataList("d_name", valueList.name);
    //         changeDataList("d_callNumber", valueList.callNumber);
    //         setCheckBox(true);
    //     }
    // }

    useEffect(() => {
        setItemPage(initList)
    }, [itemList])

    const submitData = () => {
        axios.post("https://gomdwae.pythonanywhere.com/add_data/", {
            item: itemList,
            customer: valueList,
        })
        .then((response) => {
            navigate('/payment', {
                state: response.data.amount,
            });
        })
    };

    // const style = { width:"100%", height:"100vh" };
    
    return (
        <>
        <div class="flex flex-col w-screen items-center justify-center gap-8 overflow-hidden bg-yellow-200 h-full min-h-svh">
            {/* input fields */}
            <div class="flex flex-col w-[95%] min-h-[90vh] h-full my-4 items-center mx-8 rounded-sm bg-white border border-gray-400">
                {/* image */}
                <div class="flex flex-col items-center size-[35vw] h-[80%] mt-2">
                    <img src="images/정보입력페이지.jpg" alt="" class="max-h-60"/>
                </div>
                <div class="w-4/5 relative">
                    <div class="font-pop text-[20px] mb-4 mt-3 text-center">구매자 정보 입력</div>
                    <Input dataList={dataList.name} value={name} check={check} changeDataList={changeDataList}/>
                    <Input dataList={dataList.callNumber} value={callNumber} check={check} changeDataList={changeDataList}/>
                    <Input dataList={dataList.email} value={email} check={check} changeDataList={changeDataList}/>
                    <div class="text-center font-pop mb-2 mt-3 text-[20px]">환불 계좌 정보 입력</div>
                    <Input dataList={dataList.bank} value={bank} check={check} changeDataList={changeDataList}/>
                    <Input dataList={dataList.accountNumber} value={accountNumber} check={check} changeDataList={changeDataList}/>
                </div>

                {/* <div class="font-pop text-[30px] mb-4 mt-12">배송지 정보 입력</div>
                <div class="flex flex-row gap-4 font-Pretendard text-[15px] mb-3"><div>주문자 정보와 동일합니다</div><input type="checkbox" checked={checkBox} onClick={changeCheckBox}></input></div>
                <Input dataList={dataList.d_name} value={d_name} check={check} changeDataList={changeDataList}/>
                <Input dataList={dataList.d_callNumber} value={d_callNumber} check={check} changeDataList={changeDataList}/>
                <button class={`p-3 text-[15px] text-white shadow-md border rounded-md bg-red-500 font-Pretendard mt-4 mb-3`} onClick={() => {setZipCodeModal(true);}}>우편번호 찾기</button>
                <Input dataList={dataList.zipCode} value={zipCode} check={check} changeDataList={changeDataList}/>
                <Input dataList={dataList.address} value={address} check={check} changeDataList={changeDataList}/>
                <Input dataList={dataList.detailAddress} value={detailAddress} check={check} changeDataList={changeDataList}/>
                <Input dataList={dataList.memo} value={memo} changeDataList={changeDataList}/> */}

                
                <div class="flex flex-1 w-full h-[50px] justify-center items-end">
                    <button class="max-w-[300px] max-h-[60px] w-[50vw] h-[12vw] mb-[10vw] pc:mb-[100px] mt-[5vw] bg-blue-500 text-white rounded-lg text-base shadow-lg border" onClick={() => {onModal(); window.scrollTo({top:0});}}>
                        결제하기
                    </button>
                </div>

        </div>

        {/* check modal */}
        <div class={`w-full h-full fixed top-0 bg-black bg-opacity-70 ${modal ? 'visible':'invisible'}`} onClick={() => {setModal(false);}}></div>
        <div class="flex flex-col w-full h-full bottom-0 max-2xl:bottom-[0] pc:justify-center justify-end items-center fixed invisible">
            <div class={`flex flex-col w-screen h-[85vh] pc:w-[600px] pc:h-[750px] bg-white border rounded-md z-10 ${modal ? 'visible':'invisible'}`}>
                <div class="flex flex-row items-center justify-between font-bold text-[24px] pt-4 font-noto_sans">
                    <div class="size-[28px] ml-[30px]"></div>
                    <div>최종 정보 확인</div>
                    <div class="size-[28px] mr-[30px]" onClick={() => {setModal(false)}}><img src="images/close.png" alt=""/></div>
                </div>
                <div class="flex flex-col mt-2 ml-4 h-[40%] text-2xl place-content-around">
                    <Inpo name="입금자명" value={name}/>
                    <Inpo name="연락처" value={callNumber}/>
                    <Inpo name="email" value={email}/>
                    <Inpo name="계좌번호" value={accountNumber}/>
                    {/* <Inpo name="주소" value={`${address}  ${detailAddress}  ${zipCode}`}/> */}
                </div>
                <div class="flex flex-col flex-1 h-1/3 overflow-auto bg-white">
                    <div class="self-center mt-2 text-lg">구입 품목</div>
                    {itemPage.length !== 0 ? itemPage.map((x, index) => (
                        <div key={index} class={`flex flex-row items-center border-b border-gray-300 max-h-32 mx-4 py-2 odd:bg-gray-100 even:bg-white ${x.amount===0 ? "hidden":null}`}>
                            <div class="flex justify-center items-center size-16 ml-4 border border-gray-300 bg-white"><img src={x.image} alt="" class="h-14 border border-white"></img></div>
                            <div class="flex max-pc:flex-col flex-row flex-1 gap-6">
                                <div class="self-center flex-1 w-4/5 ml-4 text-nowrap max-pc:self-start max-pc:pt-2 text-[14px] font-Freesentation">{x.item}</div>
                                <div class="flex relative h-full items-center mt-4 ">
                                    <div class="absolute right-0 self-center h-8 mr-8 max-pc:mb-8 font-bold text-nowrap">{x.count}개</div>
                                </div>                
                            </div>
                        </div>
                    )):<div class="text-center">선택된 상품이 없습니다<br/>상품을 선택해 주세요</div>}
                </div>    
                <div class="flex flex-col h-[15%] items-center justify-center border-t border-gray-400">  
                    <button class={`w-1/2 max-w-[230px] h-[50%] min-h-[40px] max-h-[70px] bottom-5 bg-blue-500 text-white rounded-lg shadow-md 
                    ${itemPage.length!==0 && modal ? "visible":"invisible"}`} onClick={submitData}>확인</button>      
                </div>
            </div>
        </div>
        </div>

        <div class={`flex justify-center items-center fixed w-screen h-lvh bg-black bg-opacity-50 top-0 ${error ? "visible":"invisible"}`}>
            <div class="flex flex-col w-[80%] h-[15%] bg-white p-5 rounded-lg">
                <div class="text-black">필수입력 항목을 입력해주세요</div>
                <div class="flex flex-1 w-full justify-end items-end text-blue-500 pr-2 underline"><button onClick={() => {setError(false)}}>확인</button></div>
            </div>    
        </div>

        {/* <div class={`fixed w-screen h-screen bg-black bg-opacity-100 top-0 ${zipCodeModal ? 'visible':'invisible'}`} onClick={() => {setZipCodeModal(false);}}>
            <div class="flex flex-col bg-white rounded-lg">
                <DaumPostcode style={style} onComplete={completeFunction}></DaumPostcode>
            </div>   
        </div> */}
    </>
)};

export default CustomerData_m;
