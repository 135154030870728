import confetti from 'canvas-confetti';
import { useEffect } from 'react';

function Success_m() {
    function firework() {
        var duration = 15 * 100;
        var animationEnd = Date.now() + duration;
        var defaults = { startVelocity: 25, spread: 360, ticks: 50, zIndex: 0 };
        //  startVelocity: 범위, spread: 방향, ticks: 갯수
      
        function randomInRange(min, max) {
          return Math.random() * (max - min) + min;
        }
      
        var interval = setInterval(function () {
          var timeLeft = animationEnd - Date.now();
      
          if (timeLeft <= 0) {
            return clearInterval(interval);
          }
      
          var particleCount = 50 * (timeLeft / duration);
          // since particles fall down, start a bit higher than random
          confetti(
            Object.assign({}, defaults, {
              particleCount,
              origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
            })
          );
          confetti(
            Object.assign({}, defaults, {
              particleCount,
              origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
            })
          );
        }, 250);
    }
    
    useEffect(() => {
      firework();
    }, []);


    return(
        <>
        <script src="https://cdn.jsdelivr.net/npm/canvas-confetti@1.5.1/dist/confetti.browser.min.js"></script>

        <div class="flex flex-col w-screen h-svh items-center justify-center overflow-hidden bg-yellow-200">
            <div class="flex flex-col w-[95%] h-[95%] gap-4 items-center justify-center mx-8 rounded-sm bg-white border border-gray-400">
              <img src='images/결제완료페이지.jpg' alt='' class="h-[30vh]"></img>
              <div class="text-[7vw] font-pop pc:text-[30px] mb-2">구매해 주셔서 감사합니다!</div>
              <div class="flex flex-col gap-8 mt-6 text-[3.5vw] pc:text-[20px] font-noto_sans mx-4 text-center">
              <div>입금 확인 후에 기입해 주신 연락처로<br/> 결제확인 문자와 QR코드를 보내드릴 예정입니다.</div>
              <div>입금 확인까지는 하루 정도가 소요될 수 있으며, <br/>만약 하루가 지나도 연락이 오지 않는다면 lazyguineapig1@gamil.com로 문의해주세요.</div>
              </div>
            </div>
        </div>
        </>
    )
};

export default Success_m;