import { useEffect, useRef, useState } from "react";
import Count from "./source/m_count";
import { useNavigate } from 'react-router-dom';
import Slide_m from "./source/m_slide";

function Main_m({itemList, ChangeList, changeState, amount}) {
    const naviagte = useNavigate();

    const bookArr = ["images/책단체샷.png", "images/3권표지.png", "images/1권표지.png",];
    const bookList = ["실장할 때까지 맛있는걸 먹는 세이아", "101~200일차", "1~100일차"];
    const bookWebp = ["images/책단체샷.webp", "images/3권표지.webp", "images/1권표지.webp",];


    const keyList = [
        "후드 세이아 키링",
        "오목눈이 라이더 키링",
        "할 말이 있는 세이아 키링",
        "세이아 아크릴 키링", 
        "수영복 호시노 아크릴 키링",
        "캠핑 마키 아크릴 키링",
        "이로하 아크릴 키링",
    ];
    const keyArr = [
        "images/후드키링.png",
        "images/오목눈이키링.png",
        "images/확성키링.png",
        "images/세이아키링.png", 
        "images/호시노키링.png", 
        "images/마키키링.png",
        "images/이로하키링.png",
    ];
    const keyWebp = [
        "images/후드키링.webp",
        "images/오목눈이키링.webp",
        "images/확성키링.webp",
        "images/세이아키링.webp", 
        "images/호시노키링.webp", 
        "images/마키키링.webp",
        "images/이로하키링.webp",
    ];


    const memoArr = [
        "images/떡메단체샷.png",
        "images/떡메분홍.png",
        "images/떡메보라.png",
    ];
    const memoList = [
        "떡메모지",
        "떡메모지 분홍",
        "떡메모지 보라"
    ];
    const memoWebp = [
        "images/떡메단체샷.webp",
        "images/떡메분홍.webp",
        "images/떡메보라.webp",
    ];

    // const stickerArr = [
    //     "images/스티커단체샷.jpg",
    //     "images/호시노 (1).png",
    //     "images/제목_없는_아트워크.png",
    //     "images/제목_없는_아트워크 9.png",
    //     "images/제목_없는_아트워크 10.png",
    //     "images/제목_없는_아트워크 12.png",
    //     "images/제목_없는_아트워크 4.png",
    // ]
    // const stickerList = [
    //     "호시노 스티커",
    //     "호시노 스티커 1",
    //     "호시노 스티커 2",
    //     "호시노 스티커 3",
    //     "호시노 스티커 4",
    //     "호시노 스티커 5",
    //     "호시노 스티커 6",
    // ]

    const idolArr = [
        "images/코롯단체.png",
        "images/아이돌세이아.png",
        "images/아이돌나기사.png",
        "images/아이돌미카.png",
    ];
    const idolList = [
        "아이돌 티파티 코롯토",
        "아이돌 세이아",
        "아이돌 나기사",
        "아이돌 미카",
    ];
    const idolWebp = [
        "images/코롯단체.webp",
        "images/아이돌세이아.webp",
        "images/아이돌나기사.webp",
        "images/아이돌미카.webp",
    ];

    const standArr = [
        "images/메이드세이아.png"
    ];
    const standList = [ 
        "메이드 세이아 아크릴 스탠드"
    ];
    const standWebp = [
        "images/메이드세이아.webp"
    ];

    const cardArr = [
        "images/엽서단체.png",
        "images/게헨나엽서.png",
        "images/트리니티엽서.png",
    ];
    const cardList = [
        "세이아의 망상 엽서",
        "세이아의 망상 트리니티 편",
        "세이아의 망상 게헨나 편",
    ];
    const cardWebp = [
        "images/엽서단체.webp",
        "images/트리니티엽서.webp",
        "images/게헨나엽서.webp",
    ];

    const bookmarkArr = [
        "images/책갈피단체샷.png",
        "images/책갈피연두.jpg",
        "images/책갈피파랑.jpg",
    ];
    const bookmarkList = [
        "책갈피",
        "티파티 책갈피",
        "세이아 책갈피",
    ];
    const bookmarkWebp = [
        "images/책갈피단체샷.webp",
        "images/책갈피연두.webp",
        "images/책갈피파랑.webp",
    ];

    const ref = useRef();

    const [modal, setModal] = useState(false);

    const submitData = () => {
        if (Object.values(itemList).some(x => x.count !== 0)) {
            if (amount > 39999) {
                changeState(choice.current.value);
            }
            naviagte('/enter_information');
        } else {
            ref.current.scrollIntoView({behavior:"smooth"});
            setModal(true);
            setTimeout(() => {setModal(false)}, 5000);
        };
    };

    const choice = useRef(null);

    return (
        <>
        {/* main */}
        <div class="flex flex-col w-screen items-center justify-center gap-8 overflow-hidden bg-yellow-200">
            {/* input fields */}
            <div class="flex flex-col w-[95%] h-[95%] items-center mx-8 my-4 rounded-sm bg-yellow-200 p-4">
                <div class="flex flex-col justify-center items-center w-full border-gray-500 rounded-md border bg-white">
                    <img src="image/청휘석_현수막.webp" class="w-[80%] mt-6 border border-black"></img>
                    <div class="flex flex-col gap-6 justify-center items-center">
                    <div class="font-pop text-[5vw] pc:text-[30px] mt-2">12월 서코 실장하라고 부스 선입금폼</div>
                    <div class="font-noto_sans text-[3vw] text-center pc:text-[20px]">굿즈 수령은 12월 21일 토요일 2시 30분까지이며<br/>그 이후엔 현장판매로 전환됩니다.</div>
                    <div class="font-noto_sans text-[3vw] pc:text-[20px]">부스 위치는 추후 안내드리겠습니다.</div>
                    <div class="font-noto_sans text-[3vw]  pc:text-[20px]">문의사항은 lazyguineapig1@gmail.com으로 연락주세요</div>
                    <div class="flex flex-col justify-center items-center mt-8 mb-20">
                        <div class="font-pop text-[3vw] mb-2 pc:text-[20px] text-center">40,000원 이상 구매시 <br/>세이아 키링 증정 이벤트를 하고 있으니<br/>많은 관심 부탁드립니다!</div>
                        <img src="images/미쳣어ㅛ.jpg" class="w-[80%] border border-black"></img>
                    </div>
                    </div>

                    <Slide_m arr={bookWebp} except={bookArr} name={bookList} data={["213p", "/", "22cm × 22cm"]}></Slide_m>
                    <div class="flex justify-center items-center text-[17px] font-Freesentation mb-28 mt-2 text-center">각 권당 27,000원<br/><br/>1~100일차 구매시 책갈피 2종을, <br/>101~200일차 구매시 세이아의 망상 엽서 2종을 드립니다</div>

                    <Slide_m arr={cardWebp} except={cardArr} name={cardList} data={["15cm×10cm"]}></Slide_m>
                    <div class="flex justify-center items-center text-[19px] font-Freesentation mb-28 mt-2">2종 4,000원</div>

                    <Slide_m arr={idolWebp} except={idolArr} name={idolList} data={["높이 약 7cm"]}></Slide_m>
                    <div class="text-[17px] font-Freesentation mb-28 mt-2 text-center"><div>각 9,000원</div><div class="flex flex-row">3종 세트로 구매시 <p class="line-through ml-2">27,000원</p>→23,000원</div></div>

                    <Slide_m arr={standWebp} except={standArr} name={standList} data={["높이 약 14cm"]}></Slide_m>
                    <div class="flex justify-center items-center text-[17px] font-Freesentation mb-28 mt-2">15000원</div>

                    <Slide_m arr={keyWebp}  except={keyArr} name={keyList} data={["높이 약 7cm"]}></Slide_m>
                    <div class="flex justify-center items-center text-[19px] font-Freesentation mb-28 mt-2">각 8,000원</div>

                    <Slide_m arr={bookmarkWebp} except={bookmarkArr} name={bookmarkList} data={["5cm × 15cm"]}></Slide_m>
                    <div class="flex justify-center items-center text-[19px] font-Freesentation mb-28 mt-2 px-[100px]">2종 4,000원</div>

                    <Slide_m arr={memoWebp} except={memoArr} name={memoList} data={["8cm × 8cm", "/", "100매"]}></Slide_m>
                    <div class="flex justify-center items-center text-[19px] font-Freesentation mb-28 mt-2">각 2,000원</div>

                    {/* <Slide_m arr={stickerArr} name={stickerList} data={["10cm × 15cm (스티커 6개가 1장입니다)"]}></Slide_m>
                    <div class="flex justify-center items-center text-[19px] font-Freesentation mb-28 mt-2">각 3,000원</div> */}
                </div>
                        
            {/* order page */}
            {itemList &&
                <div class="flex flex-col justify-center w-full gap-y-3 py-8 bg-yellow-200" ref={ref}>
                    {modal ? <div class="font-pop text-center text-[5vw]">상품을 선택해 주세요!</div>:null}
                    <Count ChangeList={ChangeList} obj={itemList.item1} item="item1"/>
                    <Count ChangeList={ChangeList} obj={itemList.item2} item="item2"/>
                    <Count ChangeList={ChangeList} obj={itemList.item3} item="item3"/>
                    <Count ChangeList={ChangeList} obj={itemList.item4} item="item4"/>
                    <Count ChangeList={ChangeList} obj={itemList.item5} item="item5"/>
                    <Count ChangeList={ChangeList} obj={itemList.item6} item="item6"/>
                    <Count ChangeList={ChangeList} obj={itemList.item7} item="item7"/>
                    <Count ChangeList={ChangeList} obj={itemList.item8} item="item8"/>
                    <Count ChangeList={ChangeList} obj={itemList.item9} item="item9"/>
                    <Count ChangeList={ChangeList} obj={itemList.item10} item="item10"/>
                    <Count ChangeList={ChangeList} obj={itemList.item11} item="item11"/>
                    <Count ChangeList={ChangeList} obj={itemList.item12} item="item12"/>
                    <Count ChangeList={ChangeList} obj={itemList.item13} item="item13"/>
                    <Count ChangeList={ChangeList} obj={itemList.item14} item="item14"/>
                    <Count ChangeList={ChangeList} obj={itemList.item15} item="item15"/>
                    <Count ChangeList={ChangeList} obj={itemList.item16} item="item16"/>
                    <Count ChangeList={ChangeList} obj={itemList.item17} item="item17"/>
                    <Count ChangeList={ChangeList} obj={itemList.item18} item="item18"/>
                    <div class={`flex flex-col font-pop text-center justify-evenly border border-gray-500 h-[95px] p-2 rounded-md bg-white ${amount>39999 ? "visible":"hidden"}`}>
                        4만원 이상 구매! 사은품 키링을 선택해주세요
                        <select ref={choice}>
                            <option value={"세이아 아크릴 키링"}>세이아 아크릴 키링</option>
                            <option value={"후드 세이아 아크릴 키링"}>후드 세이아 아크릴 키링</option>
                            <option value={"오목눈이 라이더 키링"}>오목눈이 라이더 세이아 아크릴 키링</option>
                            <option value={"할 말이 있는 세이아 키링"}>할 말이 있는 세이아 아크릴 키링</option>
                        </select>
                    </div>
                    

                    {/* submit button */}
                    <div class="flex justify-center mt-4">
                        <button class="w-[60vw] h-[15vw] pc:w-[300px] pc:h-[75px] bg-blue-500 drop text-white rounded-lg self-end shadow-xl font-noto_sans" onClick={submitData}>
                            주문하기
                        </button>
                    </div>
                </div>
            }
            </div>
            </div>
        </>
    )
}

export default Main_m; 

// 상품 소개용 이미지, 타이틀 이미지, 주문 페이지