import { useEffect, useState } from "react";
import Main_m from "./m_main";
import CustomerData_m from "./m_data";
import Payment_m from "./m_payment";

function Mobile({route, dataList}) {
    const [itemList, setItemList] = useState({
            item1: {
                item: "실장할 때까지 맛있는걸 먹는 세이아 101~200일차",
                amount: 27000,
                image: "images/3권표지.webp",
                count:0
            },
            item2: {
                item: "실장할 때까지 맛있는걸 먹는 세이아 1~100일차",
                amount: 27000,
                image: "images/1권표지.webp",
                count: 0,
            },
            item3: {
                item: "세이아의 망상 엽서 2종",
                amount: 4000,
                image: "images/엽서단체.webp",
                count: 0,
            },
            item4: {
                item: "아이돌 티파티 코롯토 3종",
                amount: 23000,
                image: "images/코롯단체.webp",
                count:0
            },
            item5: {
                item: "아이돌 세이아 코롯토",
                amount: 9000,
                image: "images/아이돌세이아.webp",
                count:0
            },
            item6: {
                item: "아이돌 나기사 코롯토",
                amount: 9000,
                image: "images/아이돌나기사.webp",
                count:0
            },
            item7: {
                item: "아이돌 미카 코롯토",
                amount: 9000,
                image: "images/아이돌미카.webp",
                count:0
            },
            item8: {
                item: "메이드 세이아 아크릴 스탠드",
                amount: 15000,
                image: "images/메이드세이아.webp",
                count:0
            },
            item9: {
                item: "후드 세이아 아크릴 키링",
                amount: 8000,
                image: "images/후드키링.webp",
                count:0
            },
            item10: {
                item: "오목눈이 라이더 키링",
                amount: 8000,
                image: "images/오목눈이키링.webp",
                count:0
            },
            item11: {
                item: "할 말이 있는 세이아 키링",
                amount: 8000,
                image: "images/확성키링.webp",
                count:0
            },
            item12: {
                item: "세이아 아크릴 키링",
                amount: 8000,
                image: "images/세이아키링.webp",
                count:0
            },
            item13: {
                item: "수영복 호시노 아크릴 키링",
                amount: 8000,
                image: "images/호시노키링.webp",
                count:0
            },
            item14: {
                item: "캠핑 마키 아크릴 키링",
                amount: 8000,
                image: "images/마키키링.webp",
                count:0
            },
            item15: {
                item: "이로하 키링",
                amount: 8000,
                image: "images/이로하키링.webp",
                count:0
            },
            item15: {
                item: "이로하 키링",
                amount: 8000,
                image: "images/이로하키링.webp",
                count:0
            },
            item16: {
                item: "책갈피 2종",
                amount: 4000,
                image: "images/책갈피단체샷.webp",
                count:0
            },
            item17: {
                item: "떡메모지 분홍",
                amount: 2000,
                image: "images/떡메분홍.png",
                count:0
            },
            item18: {
                item: "떡메모지 보라",
                amount: 2000,
                image: "images/떡메보라.png",
                count:0
            },
    });

    const [valueList, setValueList] = useState({
            name:"",
            callNumber:"",
            email:"",
            accountNumber:"",
            bank:"",
            // d_name:"",
            // d_callNumber:"",
            // zipCode:"",
            // address:"",
            // detailAddress:"",
            // memo:"",
        });

    const ChangeItemList = (item, value, list) => {
        setItemList({...itemList,
            [item]: {...list,
            count:value}
        });
    }
    
    const changeDataList = (name, value) => {
        setValueList(valueList => ({...valueList, [name]:value}))
    };

    const [main, setMain] = useState(false);
    const [user, setUser] = useState(false);
    const [payment, setPayment] = useState(false);

    const reset = () => {
        setMain(false);
        setUser(false);
        setPayment(false);
    }

    useEffect(() => {
        reset();
        if(route === "main") {
            setMain(true);
        } else if(route === "user") {
            setUser(true);
        } else if(route === "payment") {
            setPayment(true);
        }
    }, [route])

    const [service, setService] = useState();
    const changeService = () => {
        setItemList({...itemList, 
            ["service"]: {
                item: service,
                amount: 0,
                count:1,
            },
        })
    }

    useEffect(() => {
        changeService();
    }, [service])

    const changeState = (value) => {
        setService(value);
    }

    const [amount, setAmount] = useState(0);

    useEffect(() => {
        setAmount(0);
        Object.values(itemList).map((data) => {
            if (data.count > 0) {
                setAmount(amount => amount+data.amount*data.count);
            }
        })
    }, [itemList])

    return(
        <>
            <div class={`${main ? "visible":"hidden"}`}><Main_m itemList={itemList} ChangeList={ChangeItemList} changeState={changeState} amount={amount}/></div>
            <div class={`${user ? "visible":"hidden"}`}><CustomerData_m itemList={itemList} dataList={dataList} valueList={valueList} changeDataList={changeDataList} route={route} changeService={changeService} amount={amount}/></div>
            <div class={`${payment ? "visible":"hidden"}`}><Payment_m/></div>
        </>
    )
}

export default Mobile;