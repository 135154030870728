function Inpo({name, value}) {
    return (
        <>
        <div class="flex flex-row items-center max-h-[70px] h-[50%] mr-6 border-b">
            <span class="text-[4vw] pc:text-xl ml-4 flex-1 font-noto_sans">{name}</span> 
            <div class="flex items-center border border-gray-300 max-h-[40px] h-[5vh] w-[75%] pc:w-[400px] p-4 bg-gray-100">
                <div class="overflow-x-auto text-nowrap text-[4vw] text-lg text-gray-400 outline-none max-h-[35px] flex-1 bg-gray-100">{value}</div>
            </div>
        </div>
        </>
    )
}

export default Inpo;