import './App.css';
import {BrowserView, MobileView} from 'react-device-detect'
import CustomerData from './browser/data';
import Main from './browser/main';
import Payment from './browser/payment';
import Payment_m from './mobile/m_payment';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { useEffect, useState, useCallback, memo } from 'react';
import Main_m from './mobile/m_main';
import CustomerData_m from './mobile/m_data';
import QrCode from './browser/qr_code';
import Success_m from './mobile/source/m_success';
import Success from './browser/source/success';
import End from './browser/end';
import Mobile from './mobile/mobile';

// 포토샵에서 이미지에 테두리
// 이미지에 최대한 넓은 공간 확보

export default function App() {
  const saveItemList = JSON.parse(sessionStorage.getItem('itemList'));
  const saveDataList = JSON.parse(sessionStorage.getItem('dataList'));

  const defaultItemList = {
    item1: {
      item: "실장할 때까지 맛있는걸 먹는 세이아 101~200일차",
      amount: 27000,
      image: "images/3권표지.webp",
      count:0
    },
    item2: {
        item: "실장할 때까지 맛있는걸 먹는 세이아 1~100일차",
        amount: 27000,
        image: "images/1권표지.webp",
        count: 0,
    },
    item3: {
        item: "세이아의 망상 엽서 2종",
        amount: 4000,
        image: "images/엽서단체.webp",
        count: 0,
    },
    item4: {
        item: "아이돌 티파티 코롯토 3종",
        amount: 23000,
        image: "images/코롯단체.webp",
        count:0
    },
    item5: {
        item: "아이돌 세이아 코롯토",
        amount: 9000,
        image: "images/아이돌세이아.webp",
        count:0
    },
    item6: {
        item: "아이돌 나기사 코롯토",
        amount: 9000,
        image: "images/아이돌나기사.webp",
        count:0
    },
    item7: {
        item: "아이돌 미카 코롯토",
        amount: 9000,
        image: "images/아이돌미카.webp",
        count:0
    },
    item8: {
        item: "메이드 세이아 아크릴 스탠드",
        amount: 15000,
        image: "images/메이드세이아.webp",
        count:0
    },
    item9: {
        item: "후드 세이아 아크릴 키링",
        amount: 8000,
        image: "images/후드키링.webp",
        count:0
    },
    item10: {
        item: "오목눈이 라이더 키링",
        amount: 8000,
        image: "images/오목눈이키링.webp",
        count:0
    },
    item11: {
        item: "할 말이 있는 세이아 키링",
        amount: 8000,
        image: "images/확성키링.webp",
        count:0
    },
    item12: {
        item: "세이아 아크릴 키링",
        amount: 8000,
        image: "images/세이아키링.webp",
        count:0
    },
    item13: {
        item: "수영복 호시노 아크릴 키링",
        amount: 8000,
        image: "images/호시노키링.webp",
        count:0
    },
    item14: {
        item: "캠핑 마키 아크릴 키링",
        amount: 8000,
        image: "images/마키키링.webp",
        count:0
    },
    item15: {
        item: "이로하 키링",
        amount: 8000,
        image: "images/이로하키링.webp",
        count:0
    },
    item15: {
        item: "이로하 키링",
        amount: 8000,
        image: "images/이로하키링.webp",
        count:0
    },
    item16: {
        item: "책갈피 2종",
        amount: 4000,
        image: "images/책갈피단체샷.webp",
        count:0
    },
    item17: {
        item: "떡메모지 분홍",
        amount: 2000,
        image: "images/떡메분홍.png",
        count:0
    },
    item18: {
        item: "떡메모지 보라",
        amount: 2000,
        image: "images/떡메보라.png",
        count:0
    },
  }

  const defaultDataList = {
    name:"",
    callNumber:"",
    email:"",
    accountNumber:"",
    // d_name:"",
    // d_callNumber:"",
    // zipCode:"",
    // address:"",
    // detailAddress:"",
    // memo:"",
  }

  const [itemList, setItemList] = useState(saveItemList);
  const [valueList, setValueList] = useState(saveDataList);

  const ChangeItemList = (item, value, list) => {
      setItemList({...itemList,
        [item]: {...list,
          count:value}
      });
  }

  const changeDataList = (name, value) => {
    setValueList(valueList => ({...valueList, [name]:value}))
  };

  const dataList = {
    name:{
        name:"name",
        placeholder:"반드시 입금자명을 입력해주세요", 
        label:"이름"
    },
    callNumber:{
        name:"callNumber",
        placeholder:"전화번호를 입력해주세요",
        label:"연락처"
    },
    email:{
        name:"email",
        placeholder:"이메일 주소를 입력해주세요",
        label:"email"
    },
    accountNumber:{
        name:"accountNumber",
        placeholder:"환불용 계좌번호를 입력해주세요",
        label:"계좌번호"
    },
    bank:{
        name:"bank",
        placeholder:"은행명을 입력해주세요",
        label:"은행명",
    },
    // d_name:{
    //     name:"d_name",
    //     placeholder:"수령자명을 입력해주세요",
    //     label:"이름",
    // },
    // d_callNumber:{
    //     name:"d_callNumber",
    //     placeholder:"전화번호를 입력해주세요",
    //     label:"연락처",
    // },
    // zipCode:{
    //   name:"zipCode",
    //   placeholder:"우편번호를 입력해주세요",
    //   label:"우편번호",
    // },
    // address:{
    //     name:"address",
    //     placeholder:"도로명주소를 입력해주세요",
    //     label:"주소",
    // },
    // detailAddress:{
    //     name:"detailAddress",
    //     placeholder:"상세주소를 입력해주세요",
    //     label:"상세주소",
    // },
    // memo:{
    //     name:"memo",
    //     placeholder:"배송메모를 입력해주세요 (선택 사항)",
    //     label:"배송메모",
    // }
  };


  useEffect(() => {
    if(saveItemList) {
      setItemList(saveItemList);
    } else {
      sessionStorage.setItem("itemList", defaultItemList);
      setItemList(defaultItemList);
    };

    if(saveDataList) {
      setValueList(saveDataList);
    } else {
      sessionStorage.setItem("dataList", defaultDataList);
      setValueList(defaultDataList);
    }
  }, [])

  useEffect(() => {
    sessionStorage.setItem('itemList', JSON.stringify(itemList));
  }, [itemList]);

  useEffect(() => {
    sessionStorage.setItem('dataList', JSON.stringify(valueList));
  }, [valueList]);

  const [service, setService] = useState(null);
  const changeService = () => {
      setItemList({...itemList, 
          ["service"]: {
              item: service,
              amount: 0,
              count:1,
          },
      })
  }

  useEffect(() => {
    if (service !== null) {
      changeService();
    }
  }, [service])

  const changeState = (value) => {
      setService(value);
  }

  const [amount, setAmount] = useState(0);

  useEffect(() => {
      setAmount(0);
      if (itemList !== undefined && itemList !== null) {
        Object.values(itemList).map((data) => {
          if (data.count > 0) {
              setAmount(amount => amount+data.amount*data.count);
          }
        })
      }
  }, [itemList])

  return (
    <>
    <BrowserView>
      <BrowserRouter>
        <Routes>
            <Route path='/' element={<Main itemList={itemList} ChangeList={ChangeItemList} changeState={changeState} amount={amount}/>}/>
            <Route path='/enter_information' element={<CustomerData itemList={itemList} dataList={dataList} valueList={valueList} changeDataList={changeDataList}/>}/>
            <Route path='/payment' element={<Payment/>}/>
            <Route path='/complete' element={<Success/>}/>
            <Route path='/QR_code/:id' element={<QrCode/>}/>
        </Routes>
      </BrowserRouter>
    </BrowserView>
    <MobileView>
    <BrowserRouter>
        <Routes>
            {/* <Route path='/' element={<Main_m itemList={itemList} ChangeList={ChangeItemList}/>}/> */}
            {/* <Route path='/enter_information' element={<CustomerData_m itemList={itemList} dataList={dataList} valueList={valueList} changeDataList={changeDataList}/>}/> */}
            <Route path='/' element={<Mobile route={"main"} dataList={dataList}/>}/>
            <Route path='/enter_information' element={<Mobile route={"user"} dataList={dataList}/>}/>
            <Route path='/payment' element={<Mobile route={"payment"} dataList={dataList}/>}/>
            <Route path='/complete' element={<Success_m />}/>
            <Route path='/QR_code/:id' element={<QrCode/>}/>
        </Routes>
      </BrowserRouter>
    </MobileView>
    {/* <End/> */}
    </>
  )
}